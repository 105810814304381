$primary: #7dbdce;
$link: #426cb0;
$dark: #13305a;
$light: #7dbdce;

@import "node_modules/bulmaswatch/cerulean/bulmaswatch";

.body {
  background-color: #bec8d8;
}

.border-hover:hover {
  background-color: $grey;// rgba( 245, 245, 245,.4);
  //border: solid 1px $grey-lighter;
}
